<template>
  <div>
    <div
      class="event-detail vremynsk"
      v-if="EventDetail && Object.keys(EventDetail).length"
    >
      <div class="vremynsk__top-content">
        <Breadcrumbs
          :pages="[
            {
              name: EventDetail.name,
            },
          ]"
          :color="'#2186AF'"
        />
      </div>

      <div class="content first-banner__content">
        <div class="first-banner">
          <picture>
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/10years/banner_mob.jpg`"
              media="(max-width: 767px)"
            />
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/10years/banner_tab.jpg`"
              media="(max-width: 1219px)"
            />
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/10years/banner.jpg`"
              media="(min-width: 1220px)"
            />
            <img
              class="first-banner__image"
              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/10years/banner.jpg`"
            />
          </picture>
          <div class="first-banner__text">
            <div
              class="button first-banner__button button_red d-md-none"
              @click="toRegister(true)"
              v-if="!Object.keys(user).length"
            >
              Зарегистрироваться
            </div>
            <div
              class="button first-banner__button button_red d-md-none"
              @click="openIframe()"
              v-else
            >
              Смотреть
            </div>
            <div class="first-banner__title" v-html="EventDetail.name"></div>
            <div class="first-banner__description">
              Каждый город постепенно расширяется. В нем возникают широкие проспекты и уютные парки, играют фонтаны и открываются театры.         В городе живут ученые, врачи, пациенты. Они создают современный мегаполис, где у каждого может быть своя роль.<br><br>
Так же и «мегаполис» препарата Форсига постоянно развивается. 10 лет мы стремимся открывать возможности лечения таких заболеваний, как сахарный диабет 2 типа, хроническая сердечная недостаточность и хроническая болезнь почек.<br><br>
Один из показателей прогресса «города» Форсига – это число пациентов, которые доверяют своему врачу. Именно вы, врачи различных специальностей, объединенные одной целью, создаете основу для множества достижений.<br><br>
Добро пожаловать в новое десятилетие достижений «города» Форсига!
            </div>
            <div
              class="button first-banner__button button_red d-none d-xl-flex"
              @click="toRegister(true)"
              v-if="!Object.keys(user).length"
            >
              Зарегистрироваться
            </div>
            <div
              class="button first-banner__button button_red d-none d-xl-flex"
              @click="openIframe()"
              v-else
            >
              Смотреть
            </div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="event-detail__about-description mt-8">
          Каждый город постепенно расширяется. В нем возникают широкие проспекты и уютные парки, играют фонтаны и открываются театры.         В городе живут ученые, врачи, пациенты. Они создают современный мегаполис, где у каждого может быть своя роль.<br><br>
Так же и «мегаполис» препарата Форсига постоянно развивается. 10 лет мы стремимся открывать возможности лечения таких заболеваний, как сахарный диабет 2 типа, хроническая сердечная недостаточность и хроническая болезнь почек.<br><br>
Один из показателей прогресса «города» Форсига – это число пациентов, которые доверяют своему врачу. Именно вы, врачи различных специальностей, объединенные одной целью, создаете основу для множества достижений.<br><br>
Добро пожаловать в новое десятилетие достижений «города» Форсига!
        </div>
        <div class="event-detail__timer mb-0 mt-8 mt-sm-0" v-if="false">
          <div
            class="event-detail__timer-date mt-sm-4"
            v-if="timerResult.months"
          >
            {{ $m(EventDetail.date).format("DD MMMM") }}
            в
            {{
              $m
                .utc(EventDetail.date)
                .utcOffset(+3)
                .subtract(10, "minutes")
                .format("HH:mm")
            }}
            (МСК)
          </div>
          <div
            v-else-if="Object.keys(timerResult).length"
            class="event-detail__timer-row"
          >
            <div class="event-detail__timer-item" v-if="timerResult.months">
              <div class="timer__value">{{ timerResult.months }}</div>
              <div class="timer__text">Месяцев</div>
            </div>
            <div
              class="event-detail__timer-item pl-0"
              v-if="timerResult.days > 0"
            >
              <div class="timer__value">{{ timerResult.days }}</div>
              <div class="timer__text">
                {{ helpers.getNoun(timerResult.days, "день", "дня", "дней") }}
              </div>
            </div>
            <div class="event-detail__timer-item" v-if="timerResult.hours > 0">
              <div class="timer__value">{{ timerResult.hours }}</div>
              <div class="timer__text">
                {{ helpers.getNoun(timerResult.hours, "час", "часа", "часов") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.minutes > 0"
            >
              <div class="timer__value">{{ timerResult.minutes }}</div>
              <div class="timer__text">
                {{ helpers.getNoun(timerResult.minutes, "минуту", "минуты", "минут") }}
              </div>
            </div>
            <div
              class="event-detail__timer-item"
              v-if="timerResult.seconds > 0"
            >
              <div class="timer__value">{{ timerResult.seconds }}</div>
              <div class="timer__text">
                {{
                  helpers.getNoun(timerResult.seconds, "секунду", "секунды", "секунд")
                }}
              </div>
            </div>
          </div>
          <div
            v-if="!Object.keys(timerResult).length"
            @click="openIframe()"
            class="event-detail__translation button button_yellow px-6"
          >
            Смотреть
          </div>
        </div>
        <div class="event-detail__date-info">
          <div class="event-detail__date-text">
            <div class="event-detail__date-item" v-if="EventDetail.date">
              <div class="event-detail__date-item-title">Дата и время</div>
              <div class="event-detail__date-item-value">
                20 Апреля, 09:00–14:00 (МСК)
              </div>
            </div>
            <div class="event-detail__date-item" v-if="EventDetail.place">
              <div class="event-detail__date-item-title">Место</div>
              <div
                class="event-detail__date-item-value"
                v-html="EventDetail.place"
              ></div>
            </div>
            <div class="event-detail__date-item" v-if="EventDetail.place">
              <div class="event-detail__date-item-title">Формат</div>
              <div class="event-detail__date-item-value">
                {{ EventDetail.types[0].name }} /
                {{ EventDetail.types[1].name }}
              </div>
            </div>
            <div class="event-detail__date-item" v-if="EventDetail.place">
              <div
            class="button first-banner__button button_red"
            @click="openIframe()"
            v-if="!Object.keys(timerResult).length"
          >Смотреть
            </div>
            
          </div>
            <div
              class="
                button
                event-detail__date-button
                button_red
                ml-auto
                d-none d-lg-flex
              "
              @click="toRegister(true)"
              v-if="!Object.keys(user).length"
            >
              Зарегистрироваться
            </div>
          </div>
        </div>
        <div class="second-banner mt-16 mb-16">
          <picture>
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/10years/banner2_mob.jpg`"
              media="(max-width: 767px)"
            />
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/10years/banner2_tab.jpg`"
              media="(max-width: 1219px)"
            />
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/10years/banner2.jpg`"
              media="(min-width: 1220px)"
            />
            <img
              class="second-banner__image"
              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/10years/banner2.jpg`"
            />
          </picture>
        </div>
        <div class="third-banner">
          <div class="third-banner__text">
            <div class="third-banner__title">Отпразднуем вместе!</div>
            <div class="third-banner__description">
              Присоединяйтесь и поделитесь этим праздником с Вашими
              коллегами.<br />Вместе мы сможем улучшить качество жизни пациентов
              с хроническими заболеваниями!
            </div>
            <div class="third-banner__button-container">
              <div
                class="button third-banner__button button_blue"
                @click="copyLink"
              >
                Пригласить коллегу
              </div>
              <div
                class="button third-banner__button button_blue"
                @click="scrollTo('programm')"
              >
                Программа мероприятия
              </div>
            </div>
          </div>
          <picture>
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/10years/banner3_mob.jpg`"
              media="(max-width: 767px)"
            />
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/10years/banner3_tab.jpg`"
              media="(max-width: 1219px)"
            />
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/10years/banner3.jpg`"
              media="(min-width: 1220px)"
            />
            <img
              class="third-banner__image"
              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/10years/banner3.jpg`"
            />
          </picture>
        </div>
        <DefaultSlider
          :color="'#2186AF'"
          :title="'Наши эксперты'"
          :settings="settingsSlider"
          id="experts"
        >
          <template #items>
            <div
              class="slide-lector"
              v-for="(lector, ind) in lectors"
              :key="ind"
            >
              <div class="slide-lector__image-container">
                <img class="slide-lector__image" :src="lector.image" />
              </div>
              <div class="slide-lector__tag" v-html="lector.tag"></div>
              <div class="slide-lector__name" v-html="lector.name"></div>
              <div
                class="slide-lector__description"
                v-html="lector.description"
              ></div>
            </div>
          </template>
        </DefaultSlider>
        <div class="event-detail__main-info mt-8" id="programm">
          <div class="info-item__head-title">
            <div class="title-container">
              <div class="programm-list__title">
                Программа на 20 апреля 2024 г.
              </div>
              <div class="programm-list__descr">(указано московское время)</div>
            </div>
          </div>
          <div class="mt-8" v-html="programmHTML(program[0].items)"></div>
          <InfoDrop
            class="mt-16"
            v-for="(dropdown, ind) in program2"
            :key="ind"
            @openInner="openInner(dropdown.title)"
            :active="true"
            :item="{
              title: `<div class='programm-list__title'>${dropdown.title}</div><div class='programm-list__subdescr'>${dropdown.subdescr}</div>`,
              content: programmHTML(dropdown.items),
            }"
          />
        </div>
        <div class="buttons mt-6" v-if="true">
          <div
            class="button first-banner__button button_blue"
            @click="toRegister(true)"
            style="background-color: #00a0dc"
            v-if="!Object.keys(user).length"
          >
            Зарегистрироваться
          </div>
          <div
            class="button first-banner__button button_red"
            @click="openIframe()"
            v-else-if="!Object.keys(timerResult).length"
          >
            Смотреть
          </div>
        </div>
        <div class="fourth-banner mt-16">
          <picture>
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/10years/banner4_mob.jpg`"
              media="(max-width: 767px)"
            />
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/10years/banner4_tab.jpg`"
              media="(max-width: 1219px)"
            />
            <source
              :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/10years/banner4.jpg`"
              media="(min-width: 1220px)"
            />
            <img
              class="fourth-banner__image"
              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/public/assets/img/10years/banner4.jpg`"
            />
          </picture>
        </div>
      </div>
      <LibrarySlider
        v-if="sliderItems && sliderItems.length && !sliderItems.includes(null)"
        :items="sliderItems"
        :color="'#2186AF'"
        :title="'Это может быть полезным'"
        @clickMaterials="clickMaterials"
      />
    </div>
    <div v-else-if="EventDetail === 404"><Choch class="inPage" /></div>
    <div v-else-if="EventDetail === false"><Error class="inPage" /></div>
    <Facecast
      :slug="$route.meta.slug || '10years'"
      @closeFrame="openFrame = false"
      :open="openFrame"
    />
    <PopupEnd
      :eventName="'10years0420'"
      :backUrl="{ name: '10Years' }"
      @close="closePopup"
      v-if="popup"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Breadcrumbs from "../../components/pageComponents/Breadcrumbs.vue";
import InfoDrop from "../../components/pageComponents/InfoDrop.vue";
import PopupEnd from "@/components/pageComponents/PopUpEventEnd.vue";
import Facecast from "@/components/pageComponents/Facecast.vue";
import DefaultSlider from "@/components/pageComponents/DefaultSlider.vue";
import LibrarySlider from "@/components/pageComponents/LibrarySlider.vue";
import Choch from "@/views/404.vue";
import Error from "@/views/Error.vue";
import PopUpDefault from "@/components/pageComponents/PopUpDefault.vue";
export default {
  metaInfo() {
    if (this.EventDetail.name) {
      return {
        title: "Az-most - " + this.EventDetail.name.replace(/<[^>]*>?/gm, ""),
      };
    } else {
      return { title: "Az-most - " + this.EventDetail.name };
    }
  },
  name: "TimeNSK",
  components: {
    Breadcrumbs,
    InfoDrop,
    Choch,
    Error,
    PopupEnd,
    DefaultSlider,
    PopUpDefault,
    PopUpDefault,
    Facecast,
    LibrarySlider,
  },
  data: () => ({
    popup: false,
    popupActivity: false,
    popupType: "",
    openFrame: false,
    timerResult: { id: 0 },
    timerResultEnd: {},
    interval: null,
    percent: null,
    settingsSlider: {
      slidesToShow: 4,
      draggable: false,
      infinite: true,
      responsive: [
        {
          breakpoint: 1220,
          settings: {
            slidesToShow: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            infinite: true,
            variableWidth: false,
          },
        },
      ],
    },
  }),
  computed: {
    ...mapGetters([
      "user",
      "TextList",
      "VideoList",
      "PodcastsList",
      "Projects",
    ]),
    sliderItems() {
      let arr = [];
      if (this.PodcastsList && this.PodcastsList.length) {
        arr = arr.concat(
          this.PodcastsList.map((el) => ({ ...el, page: "PodcastDetail" }))
        );
      }
      if (this.TextList && this.TextList.length) {
        arr = arr.concat(
          this.TextList.map((el) => ({ ...el, page: "TextDetail" }))
        );
      }
      if (this.VideoList && this.VideoList.length) {
        arr = arr.concat(
          this.VideoList.map((el) => ({ ...el, page: "VideoDetail" }))
        );
        // .filter((video) => video.slug === 'petunina-n-a-inglt-2-sravnenie-osnovnyh-predstavitelej-klassa')
      }
      if (this.Projects && this.Projects.length) {
        arr = arr.concat(
          this.Projects.map((el) => ({ ...el, component: "project" }))
        );
      }
      return arr;
    },
    shortList() {
      return `РАН – Российская академия наук<br><br>
ТБА – тяжелая бронхиальная астма`;
    },
    EventDetail() {
      return {
        id: 466,
        types: [
          {
            id: 1,
            name: "Онлайн",
            code: "online",
          },
          {
            id: 2,
            name: "Очно",
            code: "offline",
          },
        ],
        lector: [],
        name: "Хроники времени: 10 лет возможностей",
        description: "",
        banner_description: "",
        additional_info: "",
        date: this.$route.meta.date || "2024-04-20T10:00:00+03:00",
        date_end: "2024-04-20T14:00:00+03:00",
        place: "1-й Красногвардейский пр., 21, стр. 2, Кристалл Москва-Сити",
        is_active: true,
      };
    },
    lectors() {
      return [
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/10years/experts/1.jpg`,
          tag: "Спикер",
          name: "Анциферов Михаил Борисович",
          description:
            "доктор медицинских наук, профессор. Главный внештатный специалист эндокринолог Департамента здравоохранения города Москвы",
        },
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/10years/experts/2.jpg`,
          tag: "Спикер",
          name: "Шамхалова Минара Шамхаловна",
          description:
            "д.м.н., профессор, Заведующая отделением диабетической болезни почек и посттрансплантационной реабилитации ЭНЦ ГНЦ ФГБУ «НМИЦ эндокринологии» Минздрава России.",
        },
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/10years/experts/3.jpg`,
          tag: "Спикер",
          name: "Викулова Ольга Константиновна",
          description:
            "д.м.н., Заведующая отделом эпидемиологии эндокринопатий ЭНЦ ГНЦ ФГБУ «НМИЦ эндокринологии» Минздрава России.",
        },
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/10years/experts/4.jpg`,
          tag: "Спикер",
          name: "Демидова Татьяна Юльевна",
          description:
            "д.м.н., профессор. Заведующая кафедрой эндокринологии РНИМУ им. Н.И. Пирогова.",
        },
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/10years/experts/5.jpg`,
          tag: "Спикер",
          name: "Бобкова Ирина Николаевна",
          description:
            "доктор медицинских наук, профессор кафедры внутренних, профессиональных болезней и ревматологии ПМГМУ им. Сеченова, Президент Научного Общества нефрологов России",
        },
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/10years/experts/6.jpg`,
          tag: "Спикер",
          name: "Лопатин Юрий Михайлович",
          description:
            'Член-корреспондент РАН, заведующий кафедрой кардиологии, сердечно-сосудистой и торакальной хирургии Института непрерывного медицинского и фармацевтического образования ФГБОУ ВО "ВолгГМУ" МЗ РФ,  профессор, Заслуженный врач',
        },
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/10years/experts/7.jpg`,
          tag: "Спикер",
          name: "Фомин Игорь Владимирович",
          description:
            "Профессор, доктор медицинских наук, председатель правления ОССН, профессор кафедры внутренних болезней Нижегородской государственной медицинской академии",
        },
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/10years/experts/8.jpg`,
          tag: "Спикер",
          name: "Маркова Татьяна Николаевна",
          description: "д.м.н., профессор.",
        },
        {
          image: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/10years/experts/9.jpg`,
          tag: "Спикер",
          name: "Кобалава Жанна Давидовна",
          description:
            "д.м.н., профессор, член-корреспондент РАН. Заведующая кафедрой внутренних болезней и кардиологии им.В.С Моисеева медицинского института Российского университета дружбы народов имени Патриса Лумумбы.",
        },
      ];
    },
    program() {
      return [
        {
          title: "Программа на 20 апреля 2024 г.",
          descr: "(указано московское время)",
          items: [
            {
              time: "09:00 – 10:00",
              title: "Регистрация участников мероприятия",
              icon: "talking.svg",
            },
          ],
        },
      ];
    },
    program2() {
      return [
        {
          title: "Сахарный диабет 2 типа: центральный транспортный узел.",
          subdescr:
            "<div class='subdescr__title'>Градоначальник:</div> <div class='subdescr__item'>Анциферов М. Б.</div>",
          items: [
            {
              time: "10:00 – 10:05",
              title: "Вступительное слово",
              description: "Анциферов М. Б.",
              icon: "talking.svg",
            },
            {
              time: "10:05 – 10:20",
              title:
                "Скоростное шоссе осложнений: как не упустить время интенсификации терапии СД2",
              description: "Демидова Т. Ю.",
              icon: "person.svg",
            },
            {
              time: "10:20 – 10:35",
              title:
                "Основы безопасности большого города: правила применения иНГЛТ-2",
              description: "Маркова Т. Н.",
              icon: "person.svg",
            },
            {
              time: "10:35 – 10:50",
              title: "Маршрут построен: дискуссия экспертов",
              icon: "person.svg",
            },
            {
              time: "10:50 – 11:10",
              title: "Перерыв",
              icon: "eye.svg",
            },
          ],
        },
        {
          title: "Хроническая болезнь почек: маршрут без пересадок",
          subdescr:
            "<div class='subdescr__title'>Градоначальник:</div> <div class='subdescr__item'>Шамхалова М.Ш.</div>",
          items: [
            {
              time: "11:10 – 11:15",
              title: "Вступительное слово",
              description: "Шамхалова М.Ш.",
              icon: "talking.svg",
            },
            {
              time: "11:15 – 11:30",
              title: "Дорожные указатели ранней диагностики ХБП",
              description: "Бобкова И.Н.",
              icon: "person.svg",
            },
            {
              time: "11:30 – 11:45",
              title: "СД 2 типа и ХБП: Инновации на благо большого города",
              description: "Викулова О. К.",
              icon: "person.svg",
            },
            {
              time: "11:45 – 12:00",
              title: "Маршрут построен: дискуссия экспертов",
              icon: "person.svg",
            },
            {
              time: "12:00 – 12:20",
              title: "Перерыв",
              icon: "eye.svg",
            },
          ],
        },
        {
          title: "Хроническая сердечная недостаточность: сердечный туннель",
          subdescr:
            "<div class='subdescr__title'>Градоначальник:</div> <div class='subdescr__item'>Лопатин Ю.М.</div>",
          items: [
            {
              time: "12:20 – 12:25",
              title: "Вступительное слово",
              description: "Лопатин Ю.М.",
              icon: "talking.svg",
            },
            {
              time: "12:25 – 12:40",
              title:
                "DAPA HF: скоростная трасса к новым достижениям терапии ХСН",
              description: "Фомин И.В.",
              icon: "person.svg",
            },
            {
              time: "12:40 – 13:00",
              title: "DELIVER: зажигаем огни в СЕРДЦЕ большого города",
              description: "Кобалава Ж.Д.",
              icon: "person.svg",
            },
            {
              time: "13:00 – 13:15",
              title: "Маршрут построен: дискуссия экспертов",
              icon: "person.svg",
            },
          ],
        },
      ];
    },

    galaxyProgramm() {
      let html = ``;
      let block = ``;
      for (let item of this.program) {
        block += `
              <div class="hobl__program-item">
                <div class="hobl__program-time">${item.time}</div>
                <div class="d-flex align-start hobl__program-info">
                  <img class="mr-2" src="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/vremynsk/${item.icon}"  />
                  <div class="hobl__program-info my-auto">
                    <div class="hobl__program-title">
                    ${item.title}
                    </div>
                    <div style="${
                      item.description ? "" : "display: none"
                    }" class="hobl__program-description">
                      ${item.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>`;
      }
      html = `<div class="hobl__program mb-8">${block}</div>`;
      return html;
    },
  },
  methods: {
    ...mapActions([
      "fetchEventsCalendar",
      "fetchTextMaterials",
      "fetchVideoMaterials",
      "fetchPodcasts",
      "fetchProjects",
      "fetchAlerts",
    ]),
    copyLink() {
      var copyText = `${window.location.href}`;
      navigator.clipboard.writeText(copyText);
      this.fetchAlerts({
        alerts: "Ссылка скопирована в буфер обмена",
        type: "notify",
      });
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "10years", {
          "10years": {
            "invite colleague": {
              ...this.$root.ymFields,
            },
          },
        });
      }
    },
    scrollTo(selector) {
      if (selector === "programm") {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "10years", {
            "10years": {
              "programm click": {
                ...this.$root.ymFields,
              },
            },
          });
        }
      }
      this.activeTab = parseInt(selector.replace(/^\D+/g, "")) - 1;
      const section = document.querySelector("#" + selector);

      // определяем отступ от верхнего края всей страницы (не области просмотра)
      const multyplier = this.$root.isMobile
        ? window.innerHeight
        : window.innerHeight / 2;
      const position = Math.round(section.offsetTop) + multyplier;

      // прокручиваем область просмотра до нужной позиции
      scrollTo({
        top: position,
        // плавно
        behavior: "smooth",
      });
    },
    clickMaterials(name) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "10years", {
          "10years": {
            "useful materials": {
              [name]: {
                ...this.$root.ymFields,
              },
            },
          },
        });
      }
    },
    async toRegister(reg_button) {
      if (reg_button) {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "10years", {
            "10years": {
              "reg click": {
                ...this.$root.ymFields,
              },
            },
          });
        }
      }
      localStorage.setItem("source", `10years0420`);
      localStorage.setItem("backUrl", JSON.stringify({ name: "10Years" }));
      // const route = this.$router.resolve({
      //   name: "Register",
      //   query: { email: this.$route.query.email || "" },
      // });
      // window.open(route.href, "_blank");
       window.open(this.$root.ssoLinks.registration, "_self");
    },
    programmHTML(items) {
      let html = ``;
      let block = ``;
      for (let item of items) {
        block += `
              <div class="hobl__program-item">
                <div class="hobl__program-time">${item.time}</div>
                <div class="d-flex align-start hobl__program-info">
                  <img class="mr-4" src="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/public/assets/img/10years/${item.icon}"  />
                  <div class="hobl__program-info my-auto">
                    <div class="hobl__program-title">
                    ${item.title}
                    </div>
                    <div style="${
                      item.description ? "" : "display: none"
                    }" class="hobl__program-description">
                      ${item.description}
                    </div>
                  </div>
                </div>
              </div>
            </div>`;
      }
      html = `<div class="hobl__program mb-8">${block}</div>`;
      return html;
    },
    openInner(programm) {
      if (programm) {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "10years", {
            "10years": {
              "programm drop down menu": {
                [programm]: {
                  ...this.$root.ymFields,
                }
              },
            },
          });
        }
      }
    },
    timer() {
      let date = new Date();
      const dateEnd = this.$m
        .utc(this.EventDetail.date)
        .utcOffset(+3)
        .subtract(10, "minutes");
      const today = this.$m.utc(date).utcOffset(+3);
      const diff = today.diff(dateEnd, "seconds");
      this.$set(this, "timerResult", {});
      if (diff > 0) return;
      let months = Math.abs(diff) / 2592000;
      let fullMonths = Math.floor(months);
      let days = (months - Math.floor(months)) * 30;
      let fullDays = Math.floor(days);
      let hours = (days - Math.floor(days)) * 24;
      let fullHours = Math.floor(hours);
      let minutes = (hours - Math.floor(hours)) * 60;
      let fullMinutes = Math.floor(minutes);
      let seconds = (minutes - Math.floor(minutes)) * 60;
      let fullSeconds = Math.round(seconds);
      this.$set(this, "timerResult", {});
      if (fullMonths) {
        this.$set(this.timerResult, "months", fullMonths);
      }
      if (fullDays) {
        this.$set(this.timerResult, "days", fullDays);
      }
      if (fullHours) {
        this.$set(this.timerResult, "hours", fullHours);
      }
      if (fullMinutes) {
        this.$set(this.timerResult, "minutes", fullMinutes);
      }
      if (fullSeconds) {
        this.$set(this.timerResult, "seconds", fullSeconds);
      }
    },
    async getCalendar(id) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "asthma", {
          asthma: {
            "add to calendar": {
              ...this.$root.ymFields,
            },
          },
        });
      }
      const url = await this.fetchEventsCalendar(id);
      let a = document.createElement("a");
      a.style.display = "none";
      document.body.appendChild(a);
      a.href = url;
      a.download = "event.ics";
      a.click();
      setTimeout(async () => {
        window.URL.revokeObjectURL(url);
      }, 1000);
      a.remove();
    },
    closePopup() {
      this.popup = false;
    },
    
    openIframe() {
      if (!localStorage.getItem("token") && !!!Object.keys(this.user).length) {
        if (!!!this.$route.query.access) {
          this.popup = true;
        } else {
        
          this.popup = true;
        }
        
      } else {
        this.openFrame = true;
      }
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "10years", {
          "10years": {
            "broadcast click": {
              ...this.$root.ymFields,
            },
          },
        });
      }
    },
    cyclicVideo(duration) {
      const vm = this;
      this.timer = setTimeout(() => {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "video 30 sec", {
            "video 30 sec": {
              "video name": vm.EventDetail.name,
              "video duration": duration,
              ...this.$root.ymFields,
            },
          });
        }
        vm.cyclicVideo();
      }, 30000);
    },
  },
  created() {
    this.timer();
    this.interval = setInterval(this.timer, 1000);
  },
  async mounted() {
    const vm = this;

    await this.fetchTextMaterials({
      disease: ["hbp", "hsn", "diabetes"],
    });
    await this.fetchVideoMaterials({
      disease: ["severe_asthma"],
    });
    await this.fetchPodcasts({
      disease: ["hbp", "hsn", "diabetes"],
    });
    await this.fetchProjects({
      disease: ["hbp", "hsn", "diabetes"],
    });
    
    window.addEventListener("message", this.webinarEnd, false);
    let video = document.querySelector(".event-detail__timer video");
    if (video) {
      video.addEventListener("play", function () {
        if (this.currentTime === 0) {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video start", {
              "video start": {
                "video name": vm.EventDetail.name,
                "video duration": Math.round(this.duration),
                ...vm.$root.ymFields,
              },
            });
          }
        } else {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video resume", {
              "video resume": {
                "video name": vm.EventDetail.name,
                "video duration": Math.round(this.duration),
                ...vm.$root.ymFields,
              },
            });
          }
        }
        vm.cyclicVideo(Math.round(this.duration));
      });
      video.addEventListener("pause", function () {
        if (this.currentTime !== this.duration) {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video pause", {
              "video pause": {
                "video name": vm.EventDetail.name,
                "video duration": Math.round(this.duration),
                ...vm.$root.ymFields,
              },
            });
          }
        }
        clearTimeout(vm.timer);
        vm.timer = null;
      });
      video.addEventListener("ended", function () {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "video end", {
            "video end": {
              "video name": vm.EventDetail.name,
              "video duration": Math.round(this.duration),
              ...vm.$root.ymFields,
            },
          });
        }
      });
      video.addEventListener("timeupdate", function () {
        let percent = Math.ceil((this.currentTime / this.duration) * 100);
        if (this.percent !== percent && percent !== 0 && percent % 5 === 0) {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video percentage watched", {
              "video percentage watched": {
                "video percentage watched": percent,
                ...vm.$root.ymFields,
              },
            });
          }
          this.percent = percent;
        }
      });
    }
  },
  beforeDestroy() {
    // document.querySelector("#app").style.backgroundImage = "";
    clearInterval(this.interval);
    window.removeEventListener("message", this.webinarEnd, false);
  },
  watch: {
    "$route.params.id": {
      handler: async function () {
        // if (this.$route.params.id && !this.$route.query.access) {
        //   await this.fetchEventDetail({id: this.$route.params.id});
        // }
      },
      deep: true,
      immediate: true,
    },
    async timerResult() {
      if (!Object.keys(this.timerResult).length) {
        clearInterval(this.interval);
      }
    },
    async EventDetail() {
      if (this.EventDetail.external) {
        window.open(this.EventDetail.external_link, "_self");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popup__text {
  font-family: "Roboto Slab", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  text-align: center;
  color: #656969;
}
.timer {
  &__value {
    margin-right: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
.event-detail {
  padding-top: 64px;
  @media screen and (max-width: 1220px) {
    padding-top: 0px;
  }

  &__tip {
    margin-top: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #b2b4b4;
  }

  &__description-block {
    margin-bottom: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 48px;
    background-color: #f8f8f8;

    & img {
      height: 220px;
      @media screen and (max-width: 1220px) {
        width: 100%;
        height: auto;
      }

      @media screen and (max-width: 767px) {
        margin: 0 auto;
      }
    }

    @media screen and (max-width: 1220px) {
      display: block;
      padding: 24px;
    }

    @media screen and (max-width: 767px) {
      display: block;
      padding: 16px;
    }
  }

  video {
    width: 100%;
    height: auto;
  }

  &__banner {
    width: 100%;
  }

  &__timer {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 37px;
    border-radius: 16px;
    background: #00a0dc;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
    position: relative;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }

    @media screen and (max-width: 767px) {
      padding: 16px;
    }

    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      margin-right: auto;

      @media screen and (max-width: 1220px) {
        margin-right: 0;
        margin-bottom: 32px;
      }

      @media screen and (max-width: 767px) {
        font-size: 21px;
        line-height: 26px;
      }
    }

    &-item {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 40px;
      &:last-child {
        margin-right: 0;
        &::after {
          display: none;
        }
      }

      &::after {
        display: block;
        content: "";
        width: 1px;
        height: 50%;
        background-color: #fff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }

      @media screen and (max-width: 1220px) {
        padding: 0 15px;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        padding: 0 15px;
      }
    }

    &-row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 1220px) {
        // width: 100%;
      }
    }

    &-date {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 42px;
      line-height: 48px;
      color: #ffffff;

      @media screen and (max-width: 767px) {
        font-size: 26px;
      }
    }
  }

  &__iframe {
    position: relative;
    width: 100%;
    display: none;

    & iframe {
      width: 100%;
      height: 800px;

      @media screen and (max-width: 1220px) {
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (max-width: 1220px) {
      display: none;
    }
  }

  &__iframe_opened {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    padding: 64px;

    & iframe {
      height: 100%;
    }

    @media screen and (max-width: 1220px) {
      padding: 0;
    }
  }

  &__close-btn {
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;
    z-index: 2;

    @media screen and (max-width: 1220px) {
      top: 0;
      right: 50%;
      transform: translateX(50%);
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__translation {
    max-width: 280px;
    // width: 189px;

    @media screen and (max-width: 1220px) {
      width: auto;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }

  &__date-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 24px;
    margin-top: 32px;
    margin-bottom: 40px;
    background-color: #00a0dc;
    border-radius: 20px;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      margin-top: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 32px;
    }
  }

  &__about-title {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #3c4242;
  }

  &__about-description {
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
    @media screen and (max-width: 1220px) {
      display: none;
    }
  }

  &__date-text {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__date-item {
    margin-left: 16px;
    padding-left: 16px;
    border-left: 1px solid #fff;

    &:first-child {
      border-left: 0;
    }

    @media screen and (max-width: 1220px) {
      margin-right: 0;
      margin-bottom: 16px;
      border-left: 0;
      margin-left: 0;
      padding-left: 0;
    }
  }

  &__date-item-title {
    margin-bottom: 4px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
  }

  &__date-item-value {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
  }

  &__add-calendar {
    width: 280px;
    margin-left: auto;
    @media screen and (max-width: 1220px) {
      width: 100%;
    }
  }
}
</style>

<style lang="scss">
.hobl {
  &__program {
    &-item {
      margin-bottom: 48px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      // background: rgba(0, 0, 0, 0.8);
      // padding: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
      }
    }

    &-time {
      width: fit-content;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #2186af;
      padding: 4px 8px;
      border-radius: 99px;
      // background-color: #634075;
      background-color: #f8f8f8;
      margin-right: auto;

      @media screen and (max-width: 767px) {
        margin-bottom: 16px;
      }
    }

    &-info {
      width: 75%;

      & > img {
        width: 50px;
        height: 50px;
        margin-right: 15px;
      }

      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    &-title {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      // color: #ffffff;
      color: #3c4242;
    }

    &-description {
      margin-top: 8px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      // color: #b2b4b4;
      color: #656969;
    }
  }

  &-activities {
    &__title {
      margin-bottom: 24px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #1f1f1f;
    }

    &__description {
      margin-bottom: 24px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #3c4242;
    }

    &__list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 32px;
      row-gap: 32px;

      @media screen and (max-width: 1219px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: 767px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &__item {
      padding: 40px 22px;
      background: #f8f8f8;
      border-radius: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  &-activity {
    &__icon {
      height: 117px;
      margin-bottom: 32px;

      & img {
        height: 100%;
      }
    }

    &__button {
      width: 100%;
      border: 1px solid #2186af;
      color: #2186af;
      background-color: transparent;

      &:hover {
        border-color: #2eb5eb;
        color: #2eb5eb;
      }
    }
  }
}
.event-detail {
  &__warning-dupl {
    padding: 16px;
    margin-bottom: 32px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    border-radius: 16px;
    background-color: #1b98d5;

    & svg {
      flex-shrink: 0;
    }

    & a {
      text-decoration: underline;
    }
  }

  &__warning {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #656969;
    & svg {
      flex-shrink: 0;
    }

    & a {
      text-decoration: underline;
    }
  }
  i {
    font-style: italic !important;
  }

  b,
  strong {
    font-weight: 500 !important;
  }

  ul {
    list-style: disc;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  ol {
    list-style: decimal;
    padding-left: 18px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  li {
    margin-bottom: 8px;

    & > ul {
      margin-top: 8px;

      & li {
        color: #656969;
        list-style-type: none;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.first-banner {
  overflow: hidden;
  height: 400px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1220px) {
    flex-direction: column;
    align-items: stretch;
    height: auto;
  }
  &__content {
    margin-top: 40px;
    @media screen and (max-width: 767px) {
      margin-top: 0;
      padding: 0;
    }
  }

  &__title {
    color: #1f1f1f;
    font-family: "Roboto Slab", sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px;
    margin-bottom: 24px;

    @media screen and (min-width: 1220px) {
      color: #fff;
    }

    span {
      @media screen and (min-width: 1220px) {
        color: #ff7f32;
      }
    }

    @media screen and (max-width: 767px) {
      // order: 2;
      display: block;
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 24px;
      margin-top: 24px;
      span {
        @media screen and (min-width: 1220px) {
          color: #ff7f32;
        }
        // font-size: 21px;
        // line-height: 26px;
      }
    }
  }

  &__button {
    width: 200px;
    @media screen and (max-width: 767px) {
      // order: 1;
      width: 100%;
    }
  }

  &__description {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 16px;
    display: none;
    color: #3c4242;
    @media screen and (max-width: 1220px) {
      // color: #fff;
      display: block;
    }
    @media screen and (max-width: 767px) {
      // order: 3;
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    padding-left: 74px;
    max-width: 592px;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    @media screen and (max-width: 1219px) {
      position: static;
      height: auto;
      margin-top: 16px;
      padding: 0;
    }

    @media screen and (max-width: 767px) {
      // background-color: #ccd7e0;
      padding: 24px 16px;
      display: flex;
      margin-top: 0;
      flex-direction: column;
    }
  }

  & picture {
    width: auto;
    height: 100%;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
      position: relative;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}

.second-banner {
  position: relative;
  overflow: hidden;
  height: 317px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1219px) {
    flex-direction: column;
    align-items: stretch;
    height: 274px;
    border-radius: 16px;
  }

  @media screen and (max-width: 767px) {
    border-radius: 16px;
    height: 165px;
  }

  &__title {
    color: #fff;
    font-family: "Roboto Slab", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    margin-bottom: 16px;
    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }
  &__description {
    margin-bottom: 24px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__button {
    &-container {
      display: flex;
      flex-direction: row;
      @media screen and (max-width: 767px) {
        margin-top: 48px;
        flex-direction: column;
      }
    }
    width: fit-content;
    margin-right: 16px;
    @media screen and (max-width: 767px) {
      margin-bottom: 16px;
    }
    &:last-child {
      margin-right: 0;
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    padding-left: 74px;
    max-width: 589px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    @media screen and (max-width: 1219px) {
      margin-top: auto;
      padding: 24px 16px;
      // position: static;
      // max-width: none;
      // background-color: #dee2ee;
    }
  }

  & picture {
    width: auto;
    height: 100%;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 1219px) {
      width: 100%;
      // height: auto;
      // position: relative;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}

.third-banner {
  position: relative;
  overflow: hidden;
  height: 340px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1219px) {
    flex-direction: column;
    align-items: stretch;
    height: 487px;
    border-radius: 16px;
  }

  @media screen and (max-width: 767px) {
    border-radius: 16px;
    height: 550px;
  }

  &__title {
    color: #fff;
    font-family: "Roboto Slab", sans-serif;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    margin-bottom: 16px;
    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }
  &__description {
    margin-bottom: 24px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__button {
    &-container {
      display: flex;
      flex-direction: row;
      @media screen and (max-width: 767px) {
        margin-top: 0px;
        flex-direction: column;
      }
    }
    width: fit-content;
    margin-right: 16px;
    @media screen and (max-width: 767px) {
      margin-bottom: 16px;
      width: 100%;
    }
    &:last-child {
      margin-right: 0;
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    padding-left: 74px;
    max-width: 589px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    @media screen and (max-width: 1219px) {
      margin-top: 0;
      padding: 32px;
      // position: static;
      // max-width: none;
      // background-color: #dee2ee;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
      padding: 24px 16px;
    }
  }

  & picture {
    width: auto;
    height: 100%;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 1219px) {
      width: 100%;
      // height: auto;
      // position: relative;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}

.fourth-banner {
  position: relative;
  overflow: hidden;
  height: 317px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 1219px) {
    flex-direction: column;
    align-items: stretch;
    height: 274px;
    border-radius: 16px;
  }

  @media screen and (max-width: 767px) {
    border-radius: 16px;
    height: 165px;
  }

  &__title {
    color: #fff;
    font-family: "Roboto Slab", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    margin-bottom: 16px;
    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }
  &__description {
    margin-bottom: 24px;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__button {
    &-container {
      display: flex;
      flex-direction: row;
      @media screen and (max-width: 767px) {
        margin-top: 48px;
        flex-direction: column;
      }
    }
    width: fit-content;
    margin-right: 16px;
    @media screen and (max-width: 767px) {
      margin-bottom: 16px;
    }
    &:last-child {
      margin-right: 0;
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    padding-left: 74px;
    max-width: 589px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    @media screen and (max-width: 1219px) {
      margin-top: auto;
      padding: 24px 16px;
      // position: static;
      // max-width: none;
      // background-color: #dee2ee;
    }
  }

  & picture {
    width: auto;
    height: 100%;

    @media screen and (max-width: 1219px) {
      width: 100%;
      height: auto;
    }
  }

  &__image {
    width: auto;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 1219px) {
      width: 100%;
      // height: auto;
      // position: relative;
    }

    @media screen and (max-width: 767px) {
      margin-top: 0;
    }
  }
}

.bottom-banner {
  height: 340px;
  border-radius: 16px;
  padding: 40px 54px;
  position: relative;
  overflow: hidden;
  margin-top: 64px;

  @media screen and (max-width: 1220px) {
    padding: 40px 32px;
    height: auto;
  }

  @media screen and (max-width: 767px) {
    height: 550px;
    padding: 32px;
  }

  &__logo {
    margin-bottom: 32px;

    @media screen and (max-width: 767px) {
      width: 137px;
      margin-bottom: 24px;
    }

    & svg {
      max-width: 100%;
    }
  }

  &__text {
    position: relative;
    max-width: 430px;
  }

  &__description {
    color: #3c4242;

    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__button {
    width: 208px;
    margin-top: 24px;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.slide-lector {
  // padding: 24px;
  border-radius: 16px;
  height: 100%;

  &__image-container {
    width: 280px;
    height: 270px;
    margin-bottom: 16px;
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  &__tag {
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 4px 8px;
    background-color: #015b7d;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #fff;
    width: fit-content;
  }

  &__name {
    margin-bottom: 8px;
    color: #3c4242;
    font-family: "Roboto Slab", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  &__description {
    color: #b2b4b4;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
  }
}

.default-slider {
  padding: 24px 0;
}
</style>

<style lang="scss">
.vremynsk {
  &__top-content {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .slide-lector {
    border-radius: 16px;
    height: 100%;

    &__image-container {
      width: 280px;
      height: 270px;
      margin-bottom: 16px;
      overflow: hidden;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }

    &__tag {
      margin-bottom: 16px;
      border-radius: 8px;
      padding: 4px 8px;
      background-color: #015B7D;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #fff;
      width: fit-content;
    }

    &__name {
      margin-bottom: 8px;
      color: #3c4242;
      font-family: "Roboto Slab", sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }

    &__description {
      color: #b2b4b4;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
  }

  .default-slider {
    padding: 24px 0;
  }
}
.vremynsk .programm-list__descr {
  margin-top: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #b2b4b4;
}

.vremynsk .programm-list__title {
  display: flex;
  align-items: center;
  font-family: "Roboto Slab", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 29px;
  color: #3c4242;
}

.vremynsk .programm-list__subdescr .subdescr__title {
  margin-top: 16px;
  margin-bottom: 8px;
  font-family: "Roboto-Slab", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  color: #3c4242;
}

.vremynsk .programm-list__subdescr .subdescr__item {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #3c4242;
}

.short-list {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #656969;
}

.grey_abbs {
  margin-top: 56px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #b2b4b4;
}
</style>
